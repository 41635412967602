import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import Base from './pages/Base';
import Resources from './pages/Resources';
import Impress from './pages/Impress';
import Data from './pages/Data';
import Test from './pages/Test';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout></Layout>}>
            <Route path="test" element={<Test></Test>}></Route>
            <Route path="resources" element={<Resources></Resources>}></Route>
            <Route path="data" element={<Data></Data>}></Route>
            <Route index element={<Base></Base>}></Route>
            <Route path="*" element={<Base></Base>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
//             <Route path="impress" element={<Impress></Impress>}></Route>

export default App;
