import { useNavigate } from "react-router-dom";
import BG from "../bg.jpg";
import useWindowSize from "../context/useWindowSize";

export default function Base() {
    var [w,h] = useWindowSize();
    if(w == 0) {
        w = window.innerWidth;
    }

    const nav = useNavigate();

    return <div className="mask0">
        <div className="base-x">
        <div className="topic">
            <div className="topic-text">
                <span>{"Teste in nur 5 Minuten deine "}</span>
                <span className="topic-special-text">{"Kreativität"}</span>
            </div>
            <div className="topic-options">
                <div className="button0" onClick={()=>{
                    nav("/test");
                }}><span>{"Starte einen kostenlosen Test"}</span></div>
                <div className="button0" onClick={()=>{
                    nav("/resources");
                }}><span>{"Test Mechanik ansehen"}</span></div>
            </div>
        </div>
        { w > 830 ? 
        <div className="desk">
            <div className="stand-bottom"></div>
            <div className="stander"></div>
            <div className="frame">
                <img src={BG} width={"100%"} height={"100%"}></img>
                <div className="text-overlay">
                    <div>{"Kreativität ist wertvoll"}</div>
                </div>
            </div>
        </div> : "" }
        
    </div>

    </div>;
};