export default function Resources() {
    return <div className="base-x test-x">
        <div className="test-header">{
            "Informationen"
        }</div>
        <div className="test-description">
            {"Dieser Test der verbalen Kreativität (VerbalCreativity) versucht mit Hilfe der Distanz zwischen Wörtern repräsentierenden Vektoren die Kreativität im Bereich des sprachlichen Ausdrucks darzustellen. Dabei sind die Flexibilität und Originalität der gefundenen Wörter entscheidende Faktoren."}
        </div>
        <div className="test-description">
            {"Die Aufgabenstellung fordert das Notieren von 10 möglichst unterschiedlichen Nomen (Substantiven).\nEin Beispiel: Die Wörter \"Hund\" und \"Katze\" sind sehr ähnlich zueinander, dagegen sind \"Kollaps\" und \"Hund\" überhaupt nicht ähnlich. Letzteres Wortpaar hat demnach eine größere semantische Distanz. Mit diesem Test soll im deutschsprachigen Raum evaluiert werden, inwiefern semantische Distanz und Kreativität zusammenhängen."}
        </div>
        <div className="test-description">
            {"Es handelt sich um eine erste Testversion."}
        </div>
        <div className="test-header">{
            "Auswertung"
        }</div>
        <div className="test-description">
            {"Die Auswertung der 10 Eingabewörter enthält Lücken, wie beispielsweise die mögliche Eingabe von nicht existenten Wörtern oder Adjektiven statt Nomen. Bei dem Test handelt es sich um einen digitalen Selbsttest, dabei ist der eigene Nutzen auch nur durch das eigene Respektieren der Aufgabenstellung gegeben."}
        </div>
        <div className="test-description">
            {"Fehlerhafte Eingaben werden bei der nachträglichen Analyse ausgefiltert."}
        </div>
        <div className="test-header space">{
            "Ursprüngliche Projekt"
        }</div>
        <div className="test-description">
            <span>{"Diese Aufgabe ist ein Teil meines letzten Jugend-Forscht Projektes, bei dem es um die Erstellung eines digitalen und durch Algorithmik und KI ausgewerteten Kreativitätstest handelte. Die erste Aufgabe des Tests bestand aus genau diesem Test. Den fertigen Test stelle ich als macOS-Applikation per "}</span><a href="mailto:valentinahrend@gmail.com?subject=KreativTest%20Anfrage"><span>{"E-Mail"}</span></a><span>{" zur Verfügung."}</span>
        </div>
        <div className="test-header space">{
            "Motivation"
        }</div>
        <div className="test-description">
            <span>{"Die VerbalCreativity Website speichert alle Testresultate ab und ermöglicht eine Analyse aller Daten sowie eine digitale Umfrageanbindung und gezielten Auswertung. Der deutschsprachige Test macht die Messung der Kreativität für Muttersprachler leichter. Neben diesem Mehrwert bin ich persönlich auch nach Abschluss meines ursprünglichen Projektes an NLP in Bezug auf verbale Kreativität interessiert."}</span>
        </div>
        <div className="test-header space">{
            "Wissenschaftlicher Artikel"
        }</div>
        <div className="test-description">
        <a href="https://www.pnas.org/doi/10.1073/pnas.2022340118"><span>{"Naming unrelated words predicts creativity"}</span></a><span>{" (Olson, Nahas, Chmoulevitch, Webb, Cropper auf pnas.org)"}</span>
        </div>
    </div>
};