import useWindowSize from "./context/useWindowSize";
import APP_LOGO from "./logo192.png";
import HAMBURGER from "./hamburger.png";
import { push as Menu } from 'react-burger-menu';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Layout() {

    var [w,h] = useWindowSize();
    if(w == 0) {
        w = window.innerWidth;
    }

    const [isOpen, setOpen] = useState(false);
    const nav = useNavigate();

    return <div id="layout">
        <div className="topbar">
            <div className="toplogo" onClick={()=>{
                nav("/");
            }}>
                <img src={APP_LOGO}></img>
                <div>{"VerbalCreativity"}</div>
            </div>
            {
                w < 800 ? <div className="hamburger-menu button" onClick={()=>{
                    if(w < 800) {
                        setOpen(!isOpen);

                    }                }}>
                    <img src={HAMBURGER}></img>
                </div>:
            <div className="actionmenu">
                <div className={"button" + (window.location.pathname == "/test" ? " selec" : "")} onClick={()=>{
                    nav("/test")
                }}>{"Test"}</div>
                <p className="vdivider"></p>
                <div className={"button"+ (window.location.pathname == "/resources" ? " selec" : "")} onClick={()=>{
                    nav("/resources")
                }}>{"Ressourcen"}</div>
                <p className="vdivider" ></p>
                <div className={"button"+ (window.location.pathname == "/data" ? " selec" : "")} onClick={()=>{
                    nav("/data")
                }}>{"Daten"}</div>
                <p className="vdivider"></p>
                <div className={"button"+ (window.location.pathname == "/impress" ? " selec" : "")} onClick={()=>{
                    window.open("https://valentinahrend.com/#contact", "_blank");
                }}>{"Kontakt"}</div>
            </div>
            }
        </div>
        {w  < 800 ? <Menu isOpen={isOpen} className="outer-links" burgerButtonClassName="invisible-burger">
                <div className="outer-header">
                    {"Links"}
                </div>
                <div className="outer-bar">
                <Link className="button linkx" to={"/test"}>Test</Link>
                <Link className="button linkx" to={"/resources"}>Ressourcen</Link>
                <Link className="button linkx" to={"/data"}>Daten</Link>
                <a className="button linkx" href={"https://valentinahrend.com/#contact"}>Kontakt</a>
                </div>
        </Menu>:""}
        <div className="content-pane">
            <Outlet></Outlet>
        </div>
    </div>
};