import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../context/useWindowSize";
import { sha256 } from "js-sha256";

export default function Test() {
    var [w,h] = useWindowSize();
    if(w == 0) {
        w = window.innerWidth;
    }

    const [error, setError] = useState("");
    const [testId, setTestId] = useState("");
    const [currentWords, setCurrentWords] = useState([]);
    const [response, setResponse] = useState(null);
    console.log("update");
    function checkAvailability() {
        let inputs = [];
            for (let index = 0; index < 10; index++) {
                let element = document.getElementById("input-w-" + index.toString());
                if(element == null) {
                    return false;
                }
                inputs.push(element.value);
            }
            inputs = Array.from(new Set(inputs.filter((x)=>x!=null && x.length > 0)));
            if(inputs.length < 10) {
                //setError("Für eine Auswertung sind mehr Wörter erforderlich.");
                return false;
            }
            if(inputs.some((x)=>x.split("")[0].toUpperCase() != x.split("")[0])) {
                //setError("Alle Eingaben müssen Nomen sein.");
                return false;
            }
            if(inputs.some((x)=>x.length > 28 || x.length < 2)) {
                console.log(inputs[0].length);
                //setError("Wort muss kürzer als 28 Zeichen sein.");
                return false;
            }
            return true;
    }
    let availablitiy = checkAvailability();
    console.log(availablitiy);
    const nav = useNavigate();

    if(response != null) {
        /// results are here!
        var avg = Number.parseFloat(1 - response["avg"]);
        /// 0.666 is average in this low data range!!!
        let totalAvg = 0.829350459;
        let max = 0.8920620580763857;
        let omega = Math.sqrt(0.2);
        var z = (max - ((1 / (omega * Math.sqrt(2 * Math.PI))) * Math.pow(Math.E, -0.5 * Math.pow((avg - totalAvg) / omega, 2)))) * 500;
        /// 0.4 -> 100
        console.log(z+"!!!");
        if(avg > totalAvg) {
            z = 100 + z
        }else{
            z = 100 - z;
        }
        if(z < 0) {z = 0;}
        return <div className="base-x test-x">
            <div className="test-header">
            <span className="topic-text">
            <span>{"Dein Score beträgt: "}</span>
            <span className="topic-special-text">{z.toFixed(1).toString()}</span>
            </span>
        </div>
        <div className="test-description">
            {"Mit einer Ø-Wortdistanz von "+ Number.parseFloat(1 - response["avg"]).toFixed(2).toString()+" liegst du "+(z>100?"über":(z==100?"auf":"unter"))+" dem Durchschnitt von 100."}
            {/* +" übertrifft dein Score "+pz.toFixed(0).toString()+"% aller Testteilnehmer."*/}
        </div>
        <div className="table-x">
            <table>
                <thead>
                    <th></th>
                    {response["wrd"].map((w)=>{
                        return <th>{w}</th>
                    })}
                </thead>
                <tbody>
                    {response["wrd"].map((w, i)=>{
                        return <tr>
                            <td>{w}</td>
                            {response["wrd"].map((w2, i2)=>{
                                if(i2 <= i) {
                                    return <td></td>
                                }
                                let val = response["sim"].filter((e)=>e[0] == i);
                                let realVal = 1 - val[i2 - i - 1][1];
                                return <td className={realVal > 0.66666 ? "element-green": (realVal>0.3333333 ? "element-yellow" : "element-red")} >{Number.parseFloat(realVal).toFixed(2)}</td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <div className="test-description">
            {"Die Tabelle zeigt für jedes Wortpaar die semantische Distanz an. Dabei steht 1.0 für eine sehr große Distanz und eine geringe Änhlichkeit, sowie 0.0 für eine sehr kleine semantische Distanz und große Ähnlichkeit."}
        </div>
        <div className="test-description">
            
                <span>
                    {"Aus allen Distanzen wird ein durchschnittlicher Wert berechnet, hier: " + Number.parseFloat(1 - response["avg"]).toFixed(2).toString() + ". In einer "}
                </span>
                <a href="https://www.pnas.org/content/118/25/e2022340118"><span>{"englischen Studie"}</span></a>
                <span>{" wird dessen vermuteter Zusammenhang zur verbalen Kreativität untersucht. Dieser Test stellt das gleiche Testverfahren auf Deutsch dar."}</span>
                
        </div>

        <div className="submit-test button" onClick={()=>{
            setResponse(null);
            setError("");
            setCurrentWords([]);
            setTestId("");
        }}>{"Test erneut versuchen"}</div>

        <div className="hdivider"></div>
        <div className="test-description bold">
            {"Vermerke"}
        </div>
        <div className="test-description spacing">
            <span>{"Für die Entwicklung des ursprünglichen Tests sind die Wissenschaftler "}</span>
            <a href="https://www.jayolson.org/"><span>Dr. Jay A. Olson</span></a><span>{", "}</span> 
            <a href="https://www.researchgate.net/profile/Margaret_Webb2"><span>Dr. Margaret E. Webb</span></a><span>{", "}</span> 
            <a href="https://findanexpert.unimelb.edu.au/profile/14744-simon-cropper"><span>Dr. Simon J. Cropper</span></a><span>{" und "}</span> 
            <a href="https://psychology.fas.harvard.edu/people/ellen-langer"><span>Dr. Ellen J. Langer</span></a> sowie in Assistenz Johnny Nahas und Denis Chmoulevitch verantwortlich.

        </div>
        <div className="test-description spacing">
            <span>{"Der deutschsprachige Test wird von "}</span>
            <a href="https://valentinahrend.com"><span>Valentin Ahrend</span></a><span>{" zur Verfügung gestellt."}</span>
        </div>
        <div className="test-description spacing">
            {"Es wird keinerlei Haftung übernommen und von der Legitimität des Ergebniswertes ist in diesem Stadium der Testphase abzusehen."}
        </div>
        </div>
    }

    return <div className="base-x test-x">
        <div className="test-header">
            <span>{"Trage in die folgenden Eingabefeldern "}</span>
            <span className="underline">{"10 möglichst unterschiedliche Nomen"}</span>
            <span>{" ein:"}</span>
        </div>
        <div className="test-description">
            {"Nomen (Substantive) sind Wörter vor die man einen Artikel (der, die, das) setzten kann."}
        </div>
        <div className="layer-y">
        <div className="test-layer">
            {Array.from(Array(10).keys()).map((ex, index)=>{
                    return <input onChange={(e)=>{
                        setCurrentWords([e.target.value]);
                    }} id={"input-w-"+index.toString()} placeholder={(index + 1).toString()+". Wort"}></input>
           })}
        </div>
        <div className="layer-y">
        <div className={"arrow"+(availablitiy ? " fade-in": "")}></div>
        <div className={"test-layer fade0"+(availablitiy ? " fade-in": "")}>
            <select id={"input-w-prof"}>
                <option value={"k"}>Höchster Bildungsabschluss</option>
                <option value={"1"}>Hauptschulabschluss</option>
                <option value={"2"}>Realschulabchluss</option>
                <option value={"3"}>Fachhochschulreife</option>
                <option value={"4"}>mittlerer Schulabschluss</option>
                <option value={"5"}>Abitur</option>
                <option value={"6"}>International Baccalaureate</option>
                <option value={"7"}>Bachlor</option>
                <option value={"8"}>Master</option>
                <option value={"9"}>Diplom</option>
                <option value={"10"}>Staatsexamen</option>
                <option value={"11"}>Magister</option>
                <option value={"12"}>Sonstiger Hochschulabschluss</option>
                <option value={"13"}>Doktorat</option>
                <option value={"14"}>Professur</option>
                <option value={"15"}>Meister-Zertifikat</option>
            </select>
            <select id={"input-w-gender"}>
                <option value={"k"}>Geschlecht</option>
                <option value={"w"}>Weiblich</option>
                <option value={"m"}>Männlich</option>
                <option value={"d"}>Divers</option>
            </select>
            <input id={"input-w-age"} placeholder="Alter"></input>
            <div className="error-notify">{error}</div>
            <div className="submit-test button0" onClick={()=>{
            setError("");
            /// simple validation
            let inputs = [];
            for (let index = 0; index < 10; index++) {
                inputs.push(document.getElementById("input-w-" + index.toString()).value);
            }
            inputs = Array.from(new Set(inputs.filter((x)=>x!=null && x.length > 0)));
            if(inputs.length < 8) {
                setError("Für eine Auswertung sind mehr Wörter erforderlich.");
                return;
            }
            if(inputs.some((x)=>x.split("")[0].toUpperCase() != x.split("")[0])) {
                setError("Alle Eingaben müssen Nomen sein.");
                return;
            }
            if(inputs.some((x)=>x.length > 28)) {
                console.log(inputs[0].length);
                setError("Wort muss kürzer als 28 Zeichen sein.");
                return;
            }

            // get x-values
            let x1 = document.getElementById("input-w-age").value;
            if(x1 != null && (Number.isNaN(x1) || Number.parseInt(x1) > 120 || Number.parseInt(x1) < 1)) {
                setError("Alter muss zwischen 0 und 120 liegen.");
                return;
            }
            let x2 = document.getElementById("input-w-prof").value;
            let x3 = document.getElementById("input-w-gender").value;
            if(testId.length > 0) {
                return;
            }
            setCurrentWords((x)=>inputs);
            /// fetch
            let aws_lambda_function = "https://2pypv4zlty2lsg2a3zgjnn5h640mochi.lambda-url.eu-central-1.on.aws/";
            let bias = "]r}m8+'";
            let bias1 = "heXsQ";
            let bias2 = "BSK2";
            let bias3 = "dH/DuF";
            let bias_list = [bias, bias1, bias2, bias3];
            let orientation_list = [2, 1, 3, 0];
            let empty_bias_list = ["", "", "", ""];
            bias_list.forEach((e, i)=>{
                empty_bias_list[orientation_list[i]] = e;
            });
            let finalValue = empty_bias_list.join("");
            var sh = sha256.create()
            sh = sh.update(finalValue + (Number.parseInt((new Date()).getTime() / 1000) / 60).toFixed(0).toString());
            let id = (new Date()).getTime().toString() + Math.floor(Math.random() * (1000) + 1).toString();
            setTestId(id);
            fetch(aws_lambda_function, {
                "body": JSON.stringify({
                    "hash": sh.hex(),
                    "id": id,
                    "words": inputs,
                    "x1":  x1,
                    "x2": x2,
                    "x3": x3
                }),
                "method": "POST"
            }).then((res)=>{
                if(res.status != 200) {
                    setError("Etwas ist schief gelaufen...");
                    setTestId("");
                    return;
                }
                res.json().then((obj)=>{
                    setResponse((x)=>obj);
                });
            })
            .catch((err)=>{
                setError(err.message);
                setTestId("");
            })
        }}>
            {testId.length == 0 ? "Test abgeben" : "Loading..."}
        </div>
        </div>
        </div>
        </div>
    </div>
};