import { useNavigate } from "react-router-dom";
import BG from "../bg.jpg";
import useWindowSize from "../context/useWindowSize";

export default function Data() {
    var [w,h] = useWindowSize();
    if(w == 0) {
        w = window.innerWidth;
    }

    const nav = useNavigate();
    return <div className="base-x">
        <div className="topic">
            <div className="topic-text">
                <span>{"Noch keine Daten verfügbar"}</span>
            </div>
        </div>
        { w > 830 ? 
        <div className="desk">
            <div className="stand-bottom"></div>
            <div className="stander"></div>
            <div className="frame-x">
                <div className="text-overlay">
                    <div className="gray">{"10+ Tests durchgeführt"}</div>
                </div>
            </div>
        </div> : "" }
    </div>;
};